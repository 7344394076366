import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 

export class InternalLink extends Component {
  render () {
    return (
        <Link to={this.props.href}>{ ReactHtmlParser (this.props.text) }</Link>
    );
  }
}
