import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button } from './Button';
import { CallOut } from './CallOut';
import { Card } from './Card';
import { Column } from './Column';
import { CodeBlock } from './CodeBlock';
import { CodeSampleTabs } from './CodeSampleTabs';
import { Image } from './Image';
import { ExternalLink } from './ExternalLink';
import { InternalLink } from './InternalLink';
import { OrderedList } from './OrderedList';
import { Paragraph } from './Paragraph';
import { Row } from './Row';
import { SubHeading } from './SubHeading';
import SwaggerUI from "swagger-ui-react";
import { UnorderedList } from './UnorderedList';
import { Video } from './Video';
import { VideoEmbed } from './VideoEmbed';
import { Breadcrumbs } from './Breadcrumbs';
import { ContentLayout } from './ContentLayout';
import { Footer } from './Footer';

export class Document extends Component {
  getElementComponent(element) {
    const elements = {
      button: <Button url={element.url} text={element.content} key={uuidv4()} id={element.element_id}/>,
      call_out: <CallOut content={element.content} key={uuidv4()} id={element.element_id}/>,
      card: <Card elements={element.elements} key={uuidv4()} id={element.element_id} getElementComponent={this.getElementComponent}/>,
      column: <Column elements={element.elements} key={uuidv4()} id={element.element_id} columnSize={element.column_size} getElementComponent={this.getElementComponent}/>,
      code_block: <CodeBlock content={element.content} key={uuidv4()} id={element.element_id}/>,
      code_sample_tabs: <CodeSampleTabs element={element} key={uuidv4()} id={element.element_id}/>,
      link: <ExternalLink href={element.url} text={element.content} key={uuidv4()} id={element.element_id}/>,
      image: <Image src={element.url} key={uuidv4()} id={element.element_id} styles={element.styles}/>,
      internal_link: <InternalLink href={element.url} text={element.content} key={uuidv4()} id={element.element_id}/>,
      ordered_list: <OrderedList listItems={element.elements} key={uuidv4()} id={element.element_id} getElementComponent={this.getElementComponent}/>,
      paragraph: <Paragraph text={element.content} key={uuidv4()} id={element.element_id}/>,
      row: <Row elements={element.elements} key={uuidv4()} id={element.element_id} getElementComponent={this.getElementComponent}/>,
      subheading: <SubHeading text={element.content} key={uuidv4()} id={element.element_id}/>,
      swagger_doc: <SwaggerUI url={element.url} docExpansion="list" key={uuidv4()} id={element.element_id}/>,
      unordered_list: <UnorderedList listItems={element.elements} key={uuidv4()} id={element.element_id} getElementComponent={this.getElementComponent}/>,
      video: <Video src={element.url} key={uuidv4()} id={element.element_id}/>,
      video_embed: <VideoEmbed src={element.url} key={uuidv4()} id={element.element_id}/>
    }

    return(
      elements[element.element_type]
    );
  }

  render () {
    const { routeDetails} = this.props;
    return (
      <div className="page-wrapper">
        <div className="container-fluid">
          <React.Fragment>
            <Breadcrumbs routeDetails={routeDetails}/>
            <ContentLayout routeDetails={routeDetails} getElementComponent={this.getElementComponent}/>
          </React.Fragment>
         </div>
            <Footer />

      </div>
    );
  }
}
