import React, { Component } from 'react';

export class Card extends Component {
    render() {
       
        if (this.props.elements[0].url !== null) {
            return (

                <div className="flex-fill mr-2 ml-2" style={{ width: "20rem" }}>
                    <div className="card">
                        <div className="card-body">

                            {

                                this.props.elements.map(element => {
                                    return (this.props.getElementComponent(element));
                                })
                            }
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
        
  }
}
