import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class Button extends Component {
  render () {
    return (
      <a className="btn btn-primary" href={this.props.url}>{ReactHtmlParser(this.props.text)}</a>
    );
  }
}
