import React, { Component } from 'react';
import ReactModal from 'react-modal';
import MediaQuery from 'react-responsive'

ReactModal.defaultStyles.overlay.backgroundColor = '#cccccc';

export class Image extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  render () {
    const customStyles = this.props.styles ? this.props.styles : { width: "100%", maxWidth: "500px",  marginBottom: "20px", border: "1px solid #ccc"};
    return (
      <React.Fragment>
        <MediaQuery minWidth={1224}>
          <img onClick={this.handleOpenModal} src={this.props.src} alt={this.props.src} style={customStyles}/>
          <ReactModal
              ariaHideApp={false}
              isOpen={this.state.showModal}
              contentLabel={this.props.src}
              onRequestClose={this.handleCloseModal}
              style={{
                content: {
                  top: '80px',
                  left: '320px',
                  right: '20px',
                  bottom: 'auto'
                }}
              }
            >
              <p><i className="ti-close" onClick={this.handleCloseModal}></i></p>
              <p><img src={this.props.src} alt={this.props.src} style={{width: "100%", border: "1px solid #ccc"}}/></p>
          </ReactModal>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <img onClick={this.handleOpenModal} src={this.props.src} alt={this.props.src} style={customStyles}/>
          <ReactModal
              ariaHideApp={false}
              isOpen={this.state.showModal}
              contentLabel={this.props.src}
              onRequestClose={this.handleCloseModal}
              style={{
                content: {
                  top: '80px',
                  left: '20px',
                  right: '20px',
                  bottom: 'auto'
                }}
              }
            >
              <p><i className="ti-close" onClick={this.handleCloseModal}></i></p>
              <p><img src={this.props.src} alt={this.props.src} style={{width: "100%", border: "1px solid #ccc"}}/></p>
          </ReactModal>
        </MediaQuery>
      </React.Fragment>
    );
  }
}
