import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

export class CallOut extends Component {
  render () {
    return (
        <p style={{backgroundColor: "#ffffed", fontStyle: "italic", padding: "10px 10px 10px 10px"}}>
            {ReactHtmlParser(this.props.content)}
        </p>
    );
  }
}
