import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="bos-footer">
                    <div className="container-fluid bg-bosblue">
                        <div className="row p-4">
                            <div className="col-md-3">
                                <img src="https://developers.bosframework.com/images/bos-logo-text.png" width="150" alt="BOS Logo" />
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-white">Company</h4>
                                <a href="https://www.bosframework.com/careers" target="_blank" rel="noopener noreferrer">Careers</a>
                                <br /><a href="https://www.bosframework.com/blog" target="_blank" rel="noopener noreferrer">Blog</a>
                                <br /><a href="https://www.bosframework.com/articles" target="_blank" rel="noopener noreferrer">Articles</a>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-white">Developers</h4>
                                <a href="https://console.bosframework.com/" target="_blank" rel="noopener noreferrer">Console</a>
                                <br />
                                <a href="https://www.bosframework.com/" target="_blank" rel="noreferrer">BOSFramework</a>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-white">Get In Touch</h4>
                                <a href="https://www.bosframework.com/contact" target="_blank" rel="noopener noreferrer">Contact</a>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-black">
                        <div className="row p-3">
                            <div className="col-md-6 text-white font-14">
                                &copy;2020 BOS Framework. All Rights Reserved.
                            </div>
                            <div className="col-md-6 h4 footer-bottom mb-0">
                                <a href="https://www.linkedin.com/company/bos-framework/" className="mr-3" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://twitter.com/BOSFramework" className="mr-3" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.youtube.com/channel/UC4UEoNWZglR965JfSi8esAA" className="mr-3" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
