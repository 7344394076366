import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactHtmlParser from 'react-html-parser';
import { Section } from './Section';
import { RelatedTopics } from './RelatedTopics';
import { Footer } from './Footer';
import axios from 'axios';

export class ContentLayout extends Component {
    state = {
        document: {},
        loading: true
    }

    componentDidMount() {
        const { routeDetails } = this.props;
        axios.post(`/Api/Documentation`, {
            chapterId: routeDetails.chapterId,
            unitId: routeDetails.unitId,
            documentId: routeDetails.documentId
        }).then(res => {
            const document = res.data;
            this.setState({ document: document, loading: false });
        })
    }

    render() {
        const { document, loading } = this.state;
        if (loading) {
            return (
                <div>Loading...</div>
            )
        } else {
            return (
                <React.Fragment>
                    <h2>{ReactHtmlParser(document.title)}</h2>
                    <p style={{ marginTop: "20px", fontSize: "17px", fontWeight: "200" }} key={uuidv4()}>{ReactHtmlParser(document.description)}</p>
                    {
                        document.sections.map(section => {
                            return (<Section section={section} getElementComponent={this.props.getElementComponent} key={uuidv4()} />)
                        })
                    }
                    <RelatedTopics documents={document.documents} getElementComponent={this.props.getElementComponent} key={uuidv4()} />
                  
                </React.Fragment>
            )
        }
    }
}
