import React, { Component } from 'react';

export class Column extends Component {
  render () {
    return (
        <div className={"col-sm-12 col-md-" + this.props.columnSize}>
            {
                this.props.elements.map(element => {
                    return(this.props.getElementComponent(element));
                })
            }
        </div>
    );
  }
}
