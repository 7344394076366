import React, { Component } from 'react';
import { CopyBlock, obsidian } from 'react-code-blocks'

export class CodeBlock extends Component {
  render () {
    return (
      <div style={{marginBottom: "20px"}}>
        <CopyBlock
          text={this.props.content}
          language={"csharp"}
          showLineNumbers={true}
          theme={obsidian}
          wrapLines
          codeBlock
        />
      </div>
    );
  }
}
