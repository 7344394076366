import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class Paragraph extends Component {
  render () {
    return (
        <p>{ReactHtmlParser(this.props.text)}</p>
    );
  }
}
