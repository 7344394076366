import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 

export class ExternalLink extends Component {
  render () {
    return (
        <a href={this.props.href} target="_blank" rel="noopener noreferrer">{ReactHtmlParser(this.props.text)}</a>
    );
  }
}
