import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class OrderedList extends Component {
  render () {
    return (
      <ol>
        {
          this.props.listItems.map((listItem) => {
            if (typeof listItem === 'string' || listItem instanceof String)
            {
              return(
                <li key={listItem}>{ReactHtmlParser(listItem)}</li>
            )
            } else {
              return this.props.getElementComponent(listItem)
            }
          })
        }
      </ol>
    );
  }
}
