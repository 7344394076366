import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Collapse from "@material-ui/core/Collapse";
import { Link } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

function SidebarItem({ depthStep = 10, depth = 0, expanded, item, isUnitHeading, ...rest }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const { label, items, url, onClick: onClickProp } = item;

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  function onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (onClickProp) {
      onClickProp(e, item);
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed || expanded ? (
      <KeyboardArrowDownIcon
        className={
          "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
        }
      />
    ) : (
      <KeyboardArrowRightIcon className="sidebar-item-expand-arrow" />
    );
  }
  
  return (
    <>
      <ListItem
        className="sidebar-item"
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <div
          style={{ paddingLeft: depth * depthStep }}
          className="sidebar-item-content"
        >
          <div className="sidebar-item-text">
            <NavLink tag={Link} to={url ? url : "#"} className="sidebar-link waves-effect waves-dark">
                <span className={"hide-menu"}>
                    <span className={isUnitHeading ? "nav-text unit-heading" : "nav-text"}>{label}</span> <span className="toggle-icon">{expandIcon}</span>
                </span>
            </NavLink>
          </div>
        </div>
      </ListItem>
      <Collapse in={!collapsed || expanded} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.label}${index}`}>
                {subItem === "divider" ? (
                  <Divider style={{ margin: "6px 0" }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function renderHomeLink(chapterId,  depth, depthStep) {
  if(chapterId !== "Documentation"){
    return( 
      <ListItem className="sidebar-item">
        <div style={{ paddingLeft: depth * depthStep || 0 }} className="sidebar-item-content">
          <div className="sidebar-item-text">
            <NavLink tag={Link} to={"/Documentation"} className="sidebar-link waves-effect waves-dark">
                <span className="hide-menu">
                    <span className="home-back-arrow"><KeyboardReturnIcon/> </span><span className="nav-text">Home</span>
                </span>
            </NavLink>
          </div>
        </div>
      </ListItem>
    )
  }
}

function renderOverviewLink(chapterId,  depth, depthStep) {
  if(chapterId !== "Documentation"){
    return( 
      <ListItem className="sidebar-item">
        <div style={{ paddingLeft: depth * depthStep || 0 }} className="sidebar-item-content">
          <div className="sidebar-item-text">
            <NavLink tag={Link} to={"/" + chapterId} className="sidebar-link waves-effect waves-dark">
                <span className="hide-menu">
                    <span className="nav-text">Overview</span>
                </span>
            </NavLink>
          </div>
        </div>
      </ListItem>
    )
  }
}

function Sidebar({ items, chapterTitle, chapterId, depthStep, depth, expanded }) {
  if(items && items.length){
    return (
      <aside className="left-sidebar" data-sidebarbg="skin5">
          <div className="scroll-sidebar">
            <nav className="sidebar-nav">
              <List disablePadding dense>
                {renderHomeLink(chapterId, depth, depthStep)}
                <h4 className="hide-menu" style={{color: "white", marginLeft: "20px", marginTop: "10px"}}>{chapterTitle}</h4>
                {renderOverviewLink(chapterId, depth, depthStep)}
                <Divider className="hide-menu" style={{ margin: "10px 0 10px 0", borderBottom: "solid 1px gray" }} />
                {items.map((sidebarItem, index) => (
                  <React.Fragment key={`${sidebarItem.label}${index}`}>
                      {sidebarItem === "divider" ? (
                      <Divider style={{ margin: "6px 0" }} />
                      ) : (
                      <SidebarItem
                          depthStep={depthStep}
                          depth={depth}
                          expanded={sidebarItem.expanded}
                          item={sidebarItem}
                          isUnitHeading={sidebarItem.items && sidebarItem.items.length > 0}
                      />
                      )}
                  </React.Fragment>
                ))}
              </List>
            </nav>
          </div>
      </aside>
    );
  } else {
    return null;
  }
  
}

export default Sidebar;