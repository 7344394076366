import React, { Component } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Paragraph } from './Paragraph';
import { Row } from './Row';
import { SubHeading } from './SubHeading';

export class RelatedTopics extends Component {
  render () {
    return _.chain(this.props.documents).groupBy('unit_title').map((unitDocs, unitTitle) => {
      const docsCards = unitDocs.map(doc => {
        return(
          {
            element_type: "card",
            elements: [
                {
                  element_type: "internal_link",
                  url: doc.url,
                  content: "<strong>" + doc.title + "</strong>"
                },
                {
                  element_type: "paragraph",
                  content: doc.description
                }
              ]            
          }
        )
      });
      if(unitTitle === "null") {
        return (
          <div style={{margin: "40px 10px"}} key={uuidv4()}>
            <Row elements={docsCards} getElementComponent={this.props.getElementComponent} key={uuidv4()}/>
          </div>
        );
      } else {
        return (
          <div style={{margin: "40px 10px"}} key={uuidv4()}>
            <SubHeading text={unitTitle} getElementComponent={this.props.getElementComponent} key={uuidv4()}/>
            <Paragraph text={unitDocs[0].unit_description} getElementComponent={this.props.getElementComponent} key={uuidv4()}/>
            <Row elements={docsCards} getElementComponent={this.props.getElementComponent} key={uuidv4()}/>
          </div>
        );
      }
    }).value();
  }
}
