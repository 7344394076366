import React, { Component } from 'react';

export class Row extends Component {
  render () {
    return (
        <div className="row mt-4 mb-4">
            {this.props.elements.map(element => this.props.getElementComponent(element))}
        </div>
    );
  }
}
