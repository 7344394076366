import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Document } from './components/Document';
import SideBar from './components/SideBar';
import ScrollToTop from "./components/ScrollToTop";

import './custom.css';
import axios from 'axios';
import _ from 'lodash';

export default class App extends Component {
    state = {
        routes: []
    }

    componentDidMount() {
        axios.get(`/Routes`)
            .then(res => {
                const routes = res.data;
                this.setState({ routes });
            })
    }

    formatLink(routeDetails) {
        if (routeDetails.unitId && routeDetails.documentId) {
            return "/" + routeDetails.chapterId + "/" + routeDetails.unitId + "/" + routeDetails.documentId;
        } else if (routeDetails.unitId === null && routeDetails.documentId) {
            return "/" + routeDetails.chapterId + "/" + routeDetails.documentId;
        } else {
            return "/" + routeDetails.chapterId;
        }
    };

    formatUnitLink(unitId, routeDetails) {
        return "/" + routeDetails.chapterId + "/" + unitId;
    };

    formatRouteKey(routeDetails) {
        return routeDetails.chapterId + "-" + routeDetails.unitId + "-" + routeDetails.documentId;
    }

    render() {
        const { routes } = this.state;
        const homeRouteDetails = {
            chapterId: "Documentation",
            chapterTitle: "Documentation",
            navItems: [
                { label: "Getting Started", url: "/GettingStarted" },
                { label: "User Account", url: "/UserAccount" },
                //{ label: "Billing", url: "/Billing" },
                { label: "Product Management", url: "/ProductManagement" },
                { label: "Application Management", url: "/ApplicationManagement" },
                { label: "Starter Code", url: "/StarterCode" },
                { label: "API Documentation", url: "/API" },
                { label: "Change Log", url: "/ChangeLog" }
            ]
        }
        return (
            <Layout>
                <Route key={"Documentation"} exact path={"/"} >
                    <SideBar items={homeRouteDetails.navItems} chapterId={homeRouteDetails.chapterId} chapterTitle={homeRouteDetails.chapterTitle} />
                    <Document routeDetails={homeRouteDetails} />
                    <ScrollToTop />
                </Route>
                {
                    routes.map((routeDetails) => {
                        return (
                            <Route key={this.formatRouteKey(routeDetails)} exact path={this.formatLink(routeDetails)}>
                                <SideBar items={routeDetails.navItems} chapterId={routeDetails.chapterId} chapterTitle={routeDetails.chapterTitle} />
                                <Document routeDetails={routeDetails} />
                                <ScrollToTop />
                            </Route>
                        )
                    })
                }
                {
                    _.chain(routes).groupBy('unitId').map((routes, unitId) => {
                        return (
                            <Route key={this.formatRouteKey(routes[0])} exact path={this.formatUnitLink(unitId, routes[0])}>
                                <SideBar items={routes[0].navItems} chapterId={routes[0].chapterId} chapterTitle={routes[0].chapterTitle} />
                                <Document routeDetails={routes[0]} />
                                <ScrollToTop />
                            </Route>
                        )
                    }).value()
                }
            </Layout>
        );
    }
}
