import React, { Component } from 'react';

export class Video extends Component {
  render () {
    return (
      <video style={{width: "100%", maxWidth: "800px", height: "100%"}} controls>
        <source src={this.props.src} type="video/mp4"/>
      </video>
    );
  }
}
