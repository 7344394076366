import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CodeBlock } from './CodeBlock';

export class CodeSampleTabs extends Component {
  render () {
    const element = this.props.element;
    return (
        <Tabs>
            <TabList>
                {
                    element.elements.map((el) => {
                        return (<Tab>{el.name}</Tab>)
                    })
                }
            </TabList>
            {
                element.elements.map((el) => {
                    return (
                        <TabPanel>
                            <CodeBlock content={el.content} />
                        </TabPanel>
                    )
                })
            }
        </Tabs>
    );
  }
}
