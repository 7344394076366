import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class SubHeading extends Component {
  render () {
    return (
        <h4 id={this.props.id}>{ReactHtmlParser(this.props.text)}</h4>
    );
  }
}
