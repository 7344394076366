import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Breadcrumbs extends Component {
  renderBreadcrumbs(routeDetails) {
    if (routeDetails.unitId && routeDetails.documentId)
    {
      return (
        <React.Fragment>
          <li className="breadcrumb-item" key={'breadcrumb-item1'} aria-current="page"><Link to={"/" + routeDetails.chapterId}>{routeDetails.chapterTitle}</Link></li>
          <li className="breadcrumb-item" key={'breadcrumb-item2'} aria-current="page">{routeDetails.unitTitle}</li>
          <li className="breadcrumb-item active" key={'breadcrumb-item3'} aria-current="page">{routeDetails.documentTitle}</li>
        </React.Fragment>
      )
    } else if (!routeDetails.unitId && routeDetails.documentId)
    {
      return (
        <React.Fragment>
          <li className="breadcrumb-item" key={'breadcrumb-item1'} aria-current="page"><Link to={"/" + routeDetails.chapterId}>{routeDetails.chapterTitle}</Link></li>
          <li className="breadcrumb-item active" key={'breadcrumb-item2'} aria-current="page">{routeDetails.documentTitle}</li>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <li className="breadcrumb-item active" key={'breadcrumb-item1'} aria-current="page">{routeDetails.chapterTitle}</li>
        </React.Fragment>
      )
    }
  }
  render () {
    return (
      <div className="page-breadcrumb p-0 mb-1">
        <div className="row">
          <div className="col-12 d-flex no-block align-items-center">
            <div className="">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {this.renderBreadcrumbs(this.props.routeDetails)}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
