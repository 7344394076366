import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import "swagger-ui-react/swagger-ui.css";
import './theme-feeling-blue.css';
import './SwaggerDocs.css';

export class Section extends Component {
  render () {
    const section = this.props.section;
    return (
        <section className='document-section' id={section.element_id} ref={section.element_id}>
            <h3 className={section.className}>{ReactHtmlParser(section.content)}</h3>
            {
               section.elements.map((element) => {
                 return(
                    this.props.getElementComponent(element)
                 );
               })
            }
            <hr/>
        </section>
    );
  }
}
