import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

export class NavMenu extends Component {
    render() {
        return (
            <header className="topbar" data-navbarbg="skin5">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header" data-logobg="skin5">
                        <NavLink className="nav-toggler waves-effect waves-light d-block d-md-none" to="#" onClick={(e) => e.preventDefault()}><i className="ti-menu ti-close"></i></NavLink>
                        <a className="navbar-brand" href="https://www.bosframework.com/" target="_blank" rel="noopener noreferrer">
                            <b className="logo-icon p-l-5">
                                <img src="../../images/bos-logo-new.png" alt="homepage" className="light-logo" />
                            </b>
                        </a>
                        <NavLink className="topbartoggler d-block d-md-none waves-effect waves-light" to="#" onClick={(e) => e.preventDefault()} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i className="ti-more"></i></NavLink>
                    </div>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                        <ul className="navbar-nav float-left mr-auto">
                            <li className="nav-item d-none d-md-block">
                                <NavLink className="nav-link sidebartoggler waves-effect waves-light" to="#" onClick={(e) => e.preventDefault()} data-sidebartype="mini-sidebar"><i className="mdi mdi-menu font-24"></i></NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav float-right">
                            <li className="nav-item dropdown">
                                <a className="nav-link waves-effect waves-dark" href="/ChangeLog" rel="noopener noreferrer">What's new</a>
                                <a className="nav-link waves-effect waves-dark" href="https://www.bosframework.com/blog" target="_blank" rel="noopener noreferrer">Blog</a>
                                <a className="nav-link waves-effect waves-dark" href="https://console.bosframework.com/" target="_blank" rel="noopener noreferrer">Console</a>
                                {/* <NavLink className="nav-link dropdown-toggle waves-effect waves-dark" to="#" onClick={(e) => e.preventDefault()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Help</NavLink> */}
                                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" aria-labelledby="2">
                                    <div className="p-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="p-2">
                                                    <a href="https://www.youtube.com/embed/yb6886pT9bY" target="_blank" rel="noopener noreferrer"><img src="../../images/what-is-bos-framework-thumbnail.png" style={{ width: "100%" }} alt={"video-thumbnail-what-is-bos"} /></a>
                                                    <p className="m-b-0 font-12">What is BOS</p>
                                                </div>
                                                <div className="p-2">
                                                    <a href="https://www.youtube.com/embed/pcMC54RATmM" target="_blank" rel="noopener noreferrer"><img src="../../images/hobby-tier-application-set-up-thumbnail.png" style={{ width: "100%" }} alt={"video=thumbnail-product-walkthrough"} /></a>
                                                    <p className="m-b-0 font-12">Product Walkthrough</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-2">
                                                <p><a className="anchor_cssnone" href="https://www.bosframework.com/" target="_blank" rel="noopener noreferrer">BOS Framework</a></p>
                                                <p><a className="anchor_cssnone" href="https://groups.google.com/a/bosframework.com/forum/#!forum/bosusers" target="_blank" rel="noopener noreferrer">Community</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}
